<template><span>
    <a href="#" @click="showModal">expand</a>

    <Modal
            v-show="isModalVisible"
            :quote-hash="quote_hash"
            @close="closeModal"
    />
</span>
</template>
<script>
import Modal from './components/Modal.vue';

export default {
    name: 'App',
    components: {
        Modal,
    },
    data() {
        return {
            isModalVisible: false,
            quote_hash: null
        };
    },
    methods: {
        showModal(event) {
            event.preventDefault()
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        }
    },
    created() {
        this.quote_hash = this.$parent.quote_hash
    },
};
</script>


<style scoped>

</style>