<template>
    <transition name="modal-fade" v-if="quote_form.quote_hash">
        <div class="modal-backdrop">
            <div class="modal-frame"
                 role="dialog"
                 aria-labelledby="modalTitle"
                 aria-describedby="modalDescription"
            >
                <header
                        class="modal-header"
                        id="modalTitle"
                >
                    <slot name="header">
                        <h2>{{ sku_title }}</h2>
                    </slot>
                    <button
                            type="button"
                            class="btn-close"
                            @click="close"
                            aria-label="Close modal"
                    >
                        x
                    </button>
                </header>

                <section
                        class="modal-body"
                        id="modalDescription"
                >
                    <slot name="body">
                        <div class="promo_expand">
                            <h4>Promotions:</h4>
                            <ul v-for="promotion in quote_form.promotions">
                              <li class="promo_code"><span class="promo_name">{{ promotion.name }}</span> -
                                  <span v-if="!promotion.is_public" class="promo_code">&quot;{{ promotion.promotion_code }}&quot;</span>
                                  <span v-else class="promo_public">Public Promotion</span>
                              </li>
                            </ul>
                        </div>

                        <table class="editbox sort-table">
                            <tbody>
                            <tr>
                                <th>Free</th>
                                <th>Booked Night</th>
                                <th> Nightly Rate</th>
                                <th>
                                    <strong>
                                        Extra Person(s)
                                    </strong>
                                </th>
                                <th v-for="(fee, key) in quote_form.pretax_night_fees">
                                    {{ fee.fee_name }}:
                                </th>
                                <th v-for="(fee, key) in quote_form.pretax_night_percent">
                                    {{ fee.fee_name }}:
                                </th>
                                <th>Line Subtotal</th>
                                <th>Discount
                                    <!--
                                    <br/>
                                    {{ quote_form.discount_percent }}%
                                    -->
                                </th>
                                <th>Line Total</th>
                            </tr>
                            <tr v-for="(row, dates_index) in quote_form.dates" v-if="quote_form.dates">
                                <td align="center">
                                    <input
                                            v-model="quote_form.dates[dates_index].free_night"
                                            disabled
                                            :name="dates_index"
                                            type="checkbox"
                                    />
                                </td>
                                <td nowrap="nowrap">
                                    <strong>{{ quote_form.dates[dates_index].booking_date }}</strong>
                                </td>
                                <td class="text-right" nowrap="nowrap">{{ quote_form.dates[dates_index].rate_name }} -
                                    <strong>
                                        {{ dollarUS.format(quote_form.dates[dates_index].nightly_rate) }}
                                    </strong>
                                </td>
                                <td>
                                    {{
                                        dollarUS.format(quote_form.dates[dates_index].extra_person_rate)
                                    }}
                                </td>
                                <td v-for="(fee, fee_index) in quote_form.pretax_night_fees" nowrap="nowrap">
                                    {{ dollarUS.format(quote_form.dates[dates_index].pretax_fees[fee.id]) }}
                                </td>
                                <td v-for="(fee, fee_index) in quote_form.pretax_night_percent" nowrap="nowrap">
                                    {{ dollarUS.format(quote_form.dates[dates_index].pretax_percent_fees[fee.id]) }}
                                </td>
                                <td class="text-right">
                                        {{ dollarUS.format(quote_form.dates[dates_index].subtotal) }}
                                </td>
                                <td class="text-right">
                                    <strong v-if="!quote_form.dates[dates_index].apply_discount">- Blackout - </strong>
                                    <strong class="alert-medium">
                                        ({{ dollarUS.format(quote_form.dates[dates_index].nightly_discount) }})
                                    </strong>
                                </td>
                                <td class="text-right">{{ dollarUS.format(quote_form.dates[dates_index].total) }}</td>
                            </tr>
                            <tr class="quote_subtotal">
                                <td class="text-right" nowrap="nowrap">
                                    <strong>Subtotals:</strong>
                                </td>
                                <td class="text-right" nowrap="nowrap">
                                    <strong v-if="quote_form.dates">{{ quote_form.dates.length }} nights</strong>
                                </td>
                                <td class="text-right" nowrap="nowrap">
                                    <strong>{{ rate_total }}</strong>
                                </td>
                                <td class="text-right" nowrap="nowrap">
                                    <strong>{{ extra_person_total }}</strong>
                                </td>
                                <td v-for="(fee, fee_index) in quote_form.pretax_night_fees" class="text-right"
                                    nowrap="nowrap">
                                    <strong>{{ per_night_fee_total(fee.id) }}</strong>
                                </td>
                                <td v-for="(fee, fee_index) in quote_form.pretax_night_percent" class="text-right"
                                    nowrap="nowrap">
                                    <strong>{{ per_night_percent_total(fee.id) }}</strong>
                                </td>
                                <td class="text-right" nowrap="nowrap">
                                    <strong>{{ rate_subtotal }}</strong>
                                </td>
                                <td class="text-right" nowrap="nowrap">
                                    <strong class="alert-medium">({{ discount_subtotal }})</strong>
                                </td>
                                <td class="text-right" nowrap="nowrap">
                                    <strong>{{ total_subtotal }}</strong>
                                </td>
                            </tr>
                            <tr v-for="(fee, pretax_night_fees_index) in quote_form.pretax_booking_fees">
                                <td :colspan="4 + fee_columns" class="text-right">{{ fee.fee_name }}:</td>
                                <td class="text-right">
                                    <strong>
                                        {{ dollarUS.format(quote_form.pretax_booking_fees[pretax_night_fees_index].fee_amount) }}
                                    </strong>
                                </td>
                                <td class="text-right">
                                    <strong class="alert-medium">
                                        ({{ dollarUS.format(quote_form.pretax_booking_fees[pretax_night_fees_index].fee_discount) }})
                                    </strong>
                                </td>
                                <td class="text-right">{{ dollarUS.format(quote_form.pretax_booking_fees[pretax_night_fees_index].subtotal) }}</td>
                            </tr>
                            <tr v-if="quote_form.discount_flat_rate > 0">
                                <td :colspan="6 + fee_columns" class="text-right">Flat Rate Discount:</td>
                                <td class="text-right" colspan="-2">
                                    <strong class="alert-medium">
                                        ({{ dollarUS.format(quote_form.discount_flat_rate) }})
                                    </strong>
                                </td>
                            </tr>
                            <tr class="quote_subtotal">
                                <td :colspan="6 + fee_columns" class="text-right blue-alert" nowrap="nowrap">
                                    <strong>Pretax Total:</strong>
                                </td>
                                <td class="text-right blue-alert" colspan="-2" nowrap="nowrap">
                                    <strong>{{ dollarUS.format(quote_form.grand_pretax) }}</strong>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </slot>
                </section>

                <footer class="modal-footer">
                    <slot name="footer">
                    </slot>
                    <button
                            type="button"
                            class="btn-green"
                            @click="close"
                            aria-label="Close modal"
                    >
                        Close
                    </button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
import Vue from "vue";

export default {
    name: 'Modal',
    methods: {
        close() {
            this.$emit('close');
        },
        fee_discounts() {
        },
        line_subtotal(index) {
            let total = 0
            if (this.quote_form.dates[index]) {
                let night_fees = 0.0

                let price = isNaN(parseFloat(this.quote_form.dates[index].nightly_rate)) ? 0 : parseFloat(this.quote_form.dates[index].nightly_rate)
                let extra_person = isNaN(parseFloat(this.quote_form.dates[index].extra_person)) ? 0 : parseFloat(this.quote_form.dates[index].extra_person)
                let form_extra_person = isNaN(this.quote_form.extra_persons) ? 0 : this.quote_form.extra_persons

                for (let fee in this.quote_form.dates[index].pretax_fees) {
                    night_fees += isNaN(parseFloat(this.quote_form.dates[index].pretax_fees[fee])) ? 0.0 : parseFloat(this.quote_form.dates[index].pretax_fees[fee])
                }

                total = price + (extra_person * form_extra_person) + night_fees
                let pre_percent_subtotal = total

                if (pre_percent_subtotal !== parseFloat(this.quote_form.dates[index].pre_percent_subtotal)) {
                    for (let row in this.quote_form.pretax_night_percent) {
                        this.quote_form.dates[index].pretax_percent_fees[this.quote_form.pretax_night_percent[row].id] = pre_percent_subtotal * this.quote_form.pretax_night_percent[row].fee / 100
                    }
                }

                for (let fee in this.quote_form.dates[index].pretax_percent_fees) {
                    total += isNaN(parseFloat(this.quote_form.dates[index].pretax_percent_fees[fee])) ? 0.0 : parseFloat(this.quote_form.dates[index].pretax_percent_fees[fee])
                }
                if (pre_percent_subtotal !== parseFloat(this.quote_form.dates[index].pre_percent_subtotal)) {
                    for (let row in this.quote_form.pretax_night_percent) {
                        this.quote_form.dates[index].pre_percent_subtotal = pre_percent_subtotal.toFixed(2)
                    }
                }
            }

            return total.toFixed(2)
        },
        line_total(index) {
            let total = 0
            if (this.quote_form.dates[index]) {
                total = this.line_subtotal(index) - this.quote_form.dates[index].nightly_discount
            }

            return total.toFixed(2)
        },
        per_night_fee_total(index)
        {
            let total = 0.0
            for (let x = 0; x < this.quote_form.dates.length; x++)
            {
                let tmp = parseFloat(this.quote_form.dates[x].pretax_fees[index])

                if (!isNaN(tmp))
                {
                    total += tmp
                }
            }
            return this.dollarUS.format(total)
        },
        per_night_percent_total(index)
        {
            let total = 0.0

            for (let x = 0; x < this.quote_form.dates.length; x++)
            {
                let tmp = parseFloat(this.quote_form.dates[x].pretax_percent_fees[index])

                if (!isNaN(tmp))
                {
                    total += tmp
                }
            }

            return this.dollarUS.format(total)
        },

    },
    props: ['quoteHash'],
    data() {
        return {
            dollarUS: Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }),
            quote_form: {
                dates: [],
                pretax_booking_fees: [],
                sku_booking_fees: [],
                sku_order_fees: [],
                sku_night_fees: [],
                pretax_night_fees: [],
                pretax_night_percent: [],
                event_conflicts: [],
                request_conflicts: [],
                order_form: {},
                reloading: false,
                taxes: {
                    federal: [],
                    state: []
                }
            },
        }
    },
    computed:
        {
            fee_columns() {
                return this.quote_form.pretax_night_fees.length + this.quote_form.pretax_night_percent.length
            },
            sku_title() {
                return this.quote_form.lodging_sku_id ? this.quote_form.sku_name : ''
            },
            pretax_total() {
                let total = this.pretax_total_float

                if (total) {
                    this.quote_form.pretax_price = total.toFixed(2)
                }

                return this.dollarUS.format(total)

            },

            rate_total() {
                let total = 0

                if (this.quote_form.dates) {
                    for (let row of this.quote_form.dates) {
                        if (row.nightly_rate) {
                            total = total + parseFloat(row.nightly_rate)
                        }
                    }
                }

                return this.dollarUS.format(total)
            },
            rate_subtotal() {
                let total = 0

                for (let x = 0; x < this.quote_form.dates.length; x++) {
                    let tmp = parseFloat(this.quote_form.dates[x].subtotal)
                    if (!isNaN(tmp)) {
                        total += tmp
                    }
                }

                return this.dollarUS.format(total)
            },
            total_subtotal() {
                let total = 0
                if (this.quote_form.dates) {
                    for (let x = 0; x < this.quote_form.dates.length; x++) {
                        total = total + parseFloat(this.quote_form.dates[x].total)
                    }
                }

                return this.dollarUS.format(total)
            },
            discount_subtotal() {
                let total = 0
                if (this.quote_form.dates) {
                    for (let row of this.quote_form.dates) {
                        let discount = parseFloat(row.nightly_discount)
                        if (!isNaN(discount)) {
                            total = total + discount
                        }
                    }
                }

                return this.dollarUS.format(total)
            },
            extra_person_total() {
                let total = 0.0
                if (this.quote_form.dates) {
                    for (let row of this.quote_form.dates) {
                        let person_fee = parseFloat(row.extra_person_rate)
                        if (!isNaN(person_fee)) {
                            total = total + person_fee
                        }
                    }
                }

                return this.dollarUS.format(total)

            },
            pet_total() {
                let total = 0
                if (this.quote_form.dates) {
                    for (let row of this.quote_form.dates) {
                        let pet_fee = parseFloat(row.pet)

                        if (!isNaN(pet_fee)) {
                            total = total + parseFloat(row.pet)
                        }
                    }
                }

                return this.dollarUS.format(total)
            },

        },
    created() {
        let server = window.location.hostname === 'localhost' ? 'http://localhost' : ''
        this.$http.get(server + "/ws/get_booking_quote", {
            params: {quote_hash: this.quoteHash}
        }).then(response => {
            for (const [key, value] of Object.entries(response.data)) {
                console.log(`${key}: ${value}`);
                Vue.set(this.quote_form, key, value)
            }

            console.log(this.quote_form)
        }).catch(err => {
            this.cal_state = 'error'
        })
    },

};
</script>


<style scoped>
td {
    text-align: right;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-frame {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: black;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
}

.modal-body {
    position: relative;
    padding: 20px 10px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
}

.btn-green {
    color: white;
    background: black;
    border: 1px solid black;
    border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}
</style>