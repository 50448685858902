import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

const vues = document.querySelectorAll(".sus_booking_quote");
const each = Array.prototype.forEach;



each.call(vues, function (el, index)
{
    var data = {
        class:  el.getAttribute('class'),
        quote_hash: el.getAttribute('quote_hash')
    }

    new Vue({
        data:   data,
        render: h => h(App),
    }).$mount(el)
})
